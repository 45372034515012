import React, { useEffect } from 'react';
import Landing from './Landing';
import Steps from './Steps';
import Video from './Video';
import Explore from './Explore';
import Problems from './Problems';
import Download from './Download';
import Help from './Help';
import ContactUs from './ContactUs';
import AskedQuestions from './AskedQuestions';
import Available from './Available';

const index = () => {
  return (
    <main>
      <Landing />
      <Steps />
      <Video />
      <Explore />
      <Problems />
      <Download />
      <Help />
      <ContactUs />
      <AskedQuestions />
      <Available />
    </main>
  );
};

export default index;
