import React from 'react';
import { contact, email, phone } from '../../../assets/images';

const ContactUs = () => {
  return (
    <section className='contact_section' id='contact'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-5 my-auto'>
            <div>
              <h2>
                Get in touch <br className='d-none d-lg-block' /> with us.
              </h2>
              <p className='contact_desc'>
                Have any queries, or doubts? We are happy to <br className='d-none d-lg-block' />{' '}
                help anytime!
              </p>

              <div>
                <b className='d-block mb-3'>Reach out to us at</b>

                <div className='d-flex flex-wrap mb-4'>
                  <div>
                    <img className='contact_icons' src={email} />
                    <a href='mailto:support@heita.app' className='text-white'>
                      support@heita.app
                    </a>
                  </div>
                  <div className='mx-lg-3 mt-2 mt-lg-0'>
                    <img className='contact_icons' src={phone} />
                    <a href='tel:+27 73 77 65695' className='text-white text-decoration-none'>
                      +27 73 77 65695
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <img className='img-fluid contact_img' src={contact} />
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-7'>
            <div className='contact_form'>
              <div className='row'>
                <h6 className='mb-1'>General inquiries</h6>

                <div className='col-12 col-lg-6  mt-3'>
                  <label htmlFor='name'>Your Name</label>
                  <input type='text' className='contact_input' placeholder='Your Name' id='name' />
                </div>
                <div className='col-12 col-lg-6  mt-3'>
                  <label htmlFor='email'>Your Email</label>
                  <input
                    type='email'
                    className='contact_input'
                    placeholder='example@gmail.com'
                    id='email'
                  />
                </div>
                <div className='col-12 mt-3'>
                  <label htmlFor='project'>Your Project</label>
                  <textarea className='contact_input' rows={4} placeholder='Example' id='project' />
                </div>

                <div className='col-12 mt-3'>
                  <div className='form-check my-auto'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexCheckDefault'
                    />
                    <label className='form-check-label ' htmlFor='flexCheckDefault'>
                      I agree to the private policy.
                    </label>
                  </div>
                </div>

                <div className='mt-3'>
                  <button className='msg_btn'>Send Message</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
