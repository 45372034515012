import React, { useEffect, useRef, useState } from 'react';

import playStore from '../../../assets/images/playStore.png';
import appStore from '../../../assets/images/appStore.png';
import demoVideo from '../../../assets/videos/demo.mp4';

const Landing = () => {
  const [play, setPlay] = useState(false);
  const [count, setCount] = useState(0);

  const vidRef = useRef(null);

  const handlePlayVideo = () => {
    // if (count === 0) {
    //   setCount(1);
    //   return vidRef.current.play();
    // }
    setPlay(!play);
    if (play) {
      return vidRef.current.play();
    }
    vidRef.current.pause();
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className='landing_section'>
      <div className='container_landing'>
        <div className='row'>
          <div className='col-12 col-lg-6 d-block mb-3'>
            <div className='landing_heading_section'>
              <h1 className='landing_title'>Knock, Knock....</h1>
              <h1 className='landing_title'>Whos's There ?</h1>

              <p>
                With <b className='font-w600'>Heita</b> Smart Doorbell QR You Would Know.
              </p>

              {/* <div>
                <a className='book_btn text-center text-decoration-none' href='/#contact'>
                  Book Now
                </a>
                <a href='/#start_steps' className='know_more_link'>
                  Know more <span style={{ opacity: 0 }}>so</span>
                </a>
              </div> */}

              <div className='me-auto mt-3'>
                <div>
                  <div className='d-flex mt-4 app-link-img'>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.app.heita'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img className='app-img' src={playStore} alt='' />
                    </a>
                    <a
                      href='https://apps.apple.com/app/id1622564893'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='mx-4'
                    >
                      <img className='app-img' src={appStore} alt='' />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-6 d-block video_section my-auto mx-auto position-relative  mb-3'>
            {/* <video src="https://youtu.be/PQCWN9WyMVg"autoPlay muted loop ref={vidRef}></video> */}
            <div className='d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center video_border'>
              <iframe src='https://www.youtube.com/embed/PQCWN9WyMVg?autoplay=1&mute=1&loop=1'
                frameBorder='0'
                allow='autoplay; encrypted-media'
                allowFullScreen
                title='video'
                width="300" height="445"
                style={{border:"2px solid rgb(141 122 122)"}}
                 />
            </div>
          
                    {/* <button onClick={handlePlayVideo} className='video_btn'>
              <i className={play ? 'fa fa-play-circle' : 'fa fa-pause-circle'}></i>
            </button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
