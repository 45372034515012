import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { cameraImg, bellImg, infoImg, nextIcon, prevIcon } from '../../../assets/images';

const Problems = () => {
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  };

  const testimonials = [
    {
      icon: cameraImg,
      title: 'Cost and installation',
      desc: 'Traditional doorbells often require hardware installation and may involve additional costs. Heita provides a cost-effective solution by using a simple QR code-based system, eliminating the need for hardware installation.',
    },
    {
      icon: bellImg,
      title: 'Accessibility',
      desc: 'With Heita, users can receive notifications about visitors at their door no matter where they are, as long as they have mobile phone reception. This feature is particularly useful when users are away from home or in remote locations.',
    },
    {
      icon: infoImg,
      title: 'Easy of usage',
      desc: 'Traditional doorbells often require hardware installation and may involve additional costs. Heita provides a cost-effective solution by using a simple QR code-based system, eliminating the need for hardware installation.',
    },
  ];

  return (
    <section className='problems_section' id='problems'>
      <div className='container'>
        <h1 className='text-center problems_heading'>Problems</h1>

        <OwlCarousel
          className='owl-theme'
          loop
          margin={10}
          autoplay
          autoplayTimeout={4000}
          responsive={responsive}
          navText={[
            `<span className="arrow prev"><img src=${prevIcon} /></span>`,
            `<span className="arrow next"><img src=${nextIcon} /></span>`,
          ]}
          nav={true}
          dots={false}
        >
          {testimonials.map((testimonial, i) => (
            <div key={i} className='testimonial_box'>
              <div className='d-flex align-items-start testimonial_img_section'>
                <img className='testimonial_img' src={testimonial?.icon} />
              </div>
              <h5 className='testimonial_heading'>{testimonial?.title}</h5>
              <p>{testimonial?.desc}</p>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default Problems;
