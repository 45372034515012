import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const AskedQuestions = () => {
  const accordions = [
    {
      title: 'How much does Heita cost?',
      content: (
        <div className='textIndent'>
          <p>
            No fees will be required for using our application or creating HEITA smart doorbells.
            You can create unlimited doorbells and use them as per your needs.
          </p>
        </div>
      ),
    },
    {
      title: 'Do visitors need Heita app to use my Heita?',
      content: (
        <div className='textIndent'>
          <p>
            No! We have designed Heita to be compatible with any smartphone that can read QR codes.
            Your visitors don't need to download anything at all to get in touch with you using your
            Heita. They just need to scan your code with their phone.
          </p>
        </div>
      ),
    },
    {
      title: 'What is Heita?',
      content: (
        <div className='textIndent'>
          <p>
            Heita is a QR code Smart Doorbell system, that instantly lets you know who is at the
            door no matter where you are.
          </p>
          <p>
            To get in touch with you, your visitors simply scan your code with any smartphone (they
            don't need any app), and you are instantly connected in real time.
          </p>
          <p>
            We designed Heita as a free alternative to expensive Smart Doorbells that are difficult
            to install and require a broadband connection.
          </p>
        </div>
      ),
    },
  ];

  return (
    <section className='faq_section'>
      <div className='container'>
        <h1 className='text-center problems_heading'>Frequently Asked Questions</h1>

        <div className='row'>
          {accordions?.map((data, i) => (
            <div
              className={`col-12 ${i + 1 !== accordions.length && 'col-lg-6'} mb-3 faq_accordion`}
              key={i}
            >
              <Accordion>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header>{data?.title}</Accordion.Header>
                  <Accordion.Body>{data?.content}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AskedQuestions;
