import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { help1, nextIcon, prevIcon, icon1 } from '../../../assets/images';

const Help = () => {
  const responsive = {
    0: {
      items: 1,
    },
    450: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  };

  const testimonials = [
    {
      image: help1,
      icon: icon1,
      title: 'Cost-effective',
      desc: 'The app eliminates the need for physical doorbell hardware, saving users from additional costs and installation efforts.',
    },
  ];

  return (
    <section className='help_section' id='help'>
      <div className='container'>
        <div className='col-12 col-lg-10 mx-auto'>
          <div>
            <h1 className='text-center problems_heading'>How Heita helps you?</h1>
            <div className='text-center mb-4'>
              <p>
                Heita app offers several advantages to users, providing a more{' '}
                <br className='d-none d-lg-block' /> convenient and secure way to manage
                their doorbells.
              </p>
            </div>
          </div>

          <OwlCarousel
            className='owl-theme'
            loop
            margin={10}
            autoplay
            autoplayTimeout={4000}
            responsive={responsive}
            navText={[
              `<span className="arrow prev"><img src=${prevIcon} /></span>`,
              `<span className="arrow next"><img src=${nextIcon} /></span>`,
            ]}
            nav={true}
            dots={false}
          >
            {testimonials.map((testimonial, i) => (
              <div key={i} className='help_box'>
                <div className='row'>
                  <div className='col-12 col-lg-7 col-md-6 my-auto'>
                    <div>
                      <div className='d-flex align-items-start mb-2'>
                        <img className='help_icon' src={testimonial?.icon} />
                      </div>
                      <h5 className='help_heading'>{testimonial?.title}</h5>
                      <p className='p-2'>{testimonial?.desc}</p>
                    </div>
                  </div>

                  <div className='col-12 col-lg-5 col-md-6'>
                    <img src={testimonial?.image} className='img-fluid' />
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default Help;
