import React from 'react';
import { videoImg } from '../../../assets/images';

const Video = () => {
  return (
    <section className='my-4'>
      <div className='container video1_section'>
        <img src={videoImg} className='img-fluid' />
      </div>
    </section>
  );
};

export default Video;
