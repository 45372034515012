import React from 'react';
import { downloadImg, playStore, appStore,downloadImgCentre,downloadImgLeft,downloadImgRight } from '../../../assets/images';

const Download = () => {
  return (
    <section>
      <div className='container'>
        <h1 className='text-center problems_heading'>Download Our App</h1>
        <div className='col-10 mx-auto'>
          {/* <img src={downloadImg} className='img-fluid' /> */}
          {/* <div class="d-flex flex-row">
          <div class="p-2 d-flex justify-content-center"><img src={downloadImgRight} className='img-fluid br_25' /></div>
            <div class="p-2 d-flex justify-content-center"><img src={downloadImgCentre} className='img-fluid br_25' /></div>
            <div class="p-2 d-flex justify-content-end"><img src={downloadImgLeft} className='img-fluid br_25' /></div>
          </div> */}

          <div class="container">
            <div class="row">
              <div class="col-sm p-2">
              <img src={downloadImgRight} className='img-fluid br_25' />
              </div>
              <div class="col-sm  p-2">
              <img src={downloadImgCentre} className='img-fluid br_25' />
              </div>
              <div class="col-sm p-2">
              <img src={downloadImgLeft} className='img-fluid br_25' />
              </div>
            </div>
          </div>

          <div className='d-flex my-4 app-link-img justify-content-center'>
            <a
              href='https://play.google.com/store/apps/details?id=com.app.heita'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img className='app-img' src={playStore} alt='' />
            </a>
            <a
              href='https://apps.apple.com/app/id1622564893'
              target='_blank'
              rel='noopener noreferrer'
              className='mx-4'
            >
              <img className='app-img' src={appStore} alt='' />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;
