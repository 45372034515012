import React from 'react';

import playStore from '../../../assets/images/playStore.png';
import appStore from '../../../assets/images/appStore.png';
import { exportImg } from '../../../assets/images';

const Explore = () => {
  return (
    <section>
      <div className='container'>
        <img src={exportImg} className='img-fluid' style={{ marginBottom: '3rem' }} />
        {/* <div className='explore_content text-center'>
          <div>
            <h1>
              Want to explore our app <br className='d-none d-lg-block' /> download right now!!
            </h1>

            <div className='d-flex mt-4 app-link-img justify-content-center'>
              <a
                href='https://play.google.com/store/apps/details?id=com.app.heita'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img className='app-img' src={playStore} alt='' />
              </a>
              <a
                href='https://apps.apple.com/app/id1622564893'
                target='_blank'
                rel='noopener noreferrer'
                className='mx-4'
              >
                <img className='app-img' src={appStore} alt='' />
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Explore;
