import React from 'react';
import { step1, step2, step3 } from '../../../assets/images';

const Steps = () => {
  const steps = [
    {
      image: step1,
      title: 'Download The App',
      desc: (
        <span>
          Download the Heita App <br /> from your <br /> favorite app store
        </span>
      ),
    },
    {
      image: step2,
      title: 'Create Your Smart doorbell',
      desc: (
        <span>
          Register on the app and <br /> create your first <br /> HEITA bell
        </span>
      ),
    },
    {
      image: step3,
      title: "Know who's There!",
      desc: (
        <span>
          Scan the QR-Code, and you will <br /> know who is at the door no <br /> matter where you
          are.
        </span>
      ),
    },
  ];

  return (
    <section className='steps_section' id='start_steps'>
      <div className='container'>
        <h1 className='steps_title'>Easy To Start In 3 STEPS</h1>
        <div className='row'>
          {steps.map((step, i) => (
            <div className='col-12 col-md-6 col-lg-4' key={i}>
              <div className='process_steps m-2 text-center'>
                <div className='step_img d-flex justify-content-center'>
                  <img src={step?.image} className='img-fluid my-auto align-self-center' />
                </div>
                <div className='step_content text-center'>
                  <h5 className='step_tittle text-capitalize'>{step?.title}</h5>
                  <p className='step_desc'>{step?.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Steps;
