import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../assets/images/logo.png';
import { facebookImg, twitterImg, youtubeImg } from '../../assets/images';

function Header() {
  return (
    <>
      <Navbar
        expand='lg'
        // className='fixed-top'
        // style={{ background: '#141b20' }}
      >
        <Container>
          <Navbar.Brand href='#'>
            <img src={logo} className='headerLogo' alt='logo' />
          </Navbar.Brand>
          <Navbar.Toggle>
            <i className='fa fa-bars text-white' aria-hidden='true'></i>
          </Navbar.Toggle>
          <Navbar.Collapse id='navbarScroll'>
            <Nav className='nav_links ms-auto my-2 my-lg-0' navbarScroll>
              <Nav.Link className='font-w500 nav_active nav_link' href='/#'>
                Home
              </Nav.Link>
              <Nav.Link className='font-w400 nav_link' href='/#problems'>
                Problems
              </Nav.Link>
              <Nav.Link className='font-w400 nav_link' href='/#help'>
                How Heita helps you?
              </Nav.Link>
              <Nav.Link className='font-w400 nav_link' href='/#contact'>
                About Us
              </Nav.Link>
              <Nav.Link className='font-w400 nav_link' href='/#contact'>
                Contact Us
              </Nav.Link>
              <Nav.Link>
                <div className='d-flex'>
                  <a
                    className='nav_icons'
                    href='https://www.facebook.com/heitaapp'
                    onClick={() => window.open('https://www.facebook.com/heitaapp')}
                    target='_blank'
                  >
                    <i className='fab fa-facebook-f'></i>
                    {/* <img src={facebookImg} className='img-fluid' /> */}
                  </a>
                  <a
                    className='nav_icons'
                    href='https://twitter.com/HeitaApp'
                    onClick={() => window.open('https://twitter.com/HeitaApp')}
                    target='_blank'
                  >
                    <i className='fab fa-twitter'></i>
                    {/* <img src={twitterImg} className='img-fluid' /> */}
                  </a>
                  <a
                    className='nav_icons'
                    href='https://www.instagram.com/heitaapp/'
                    onClick={() => window.open('https://www.instagram.com/heitaapp/')}
                    target='_blank'
                  >
                    <i className='fab fa-instagram'></i>
                  </a>
                  <a
                    className='nav_icons'
                    href='https://www.youtube.com/@heitaapp9202'
                    onClick={() => window.open('https://www.youtube.com/@heitaapp9202')}
                    target='_blank'
                  >
                    <i className='fab fa-youtube'></i>
                    {/* <img src={youtubeImg} className='img-fluid' /> */}
                  </a>
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <div className='empty_header'></div> */}
    </>
  );
}

export default Header;
