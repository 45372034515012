import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LandingPage from './jsx/components/LandingPage';
import Footer from './jsx/layouts/Footer';
import Header from './jsx/layouts/Header';
import PrivatePolicy from './jsx/components/PrivatePolicy';
import Terms from './jsx/components/Terms';

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/home' element={<LandingPage />} />
        <Route path='/polices' element={<PrivatePolicy />} />
        <Route path='/terms' element={<Terms />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
