import React from 'react';
import { appStore, availablenew, playStore } from '../../../assets/images';

const Available = () => {
  return (
    <section className='section_available'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-5 my-auto'>
            <div>
              <h2>
                We are available on App <br className='d-none d-lg-block' /> Store and Google{' '}
                <br className='d-none d-lg-block' /> Play Store
              </h2>
              <p className='my-4 available_desc'>
                For us, helping our customer to fulfill their needs is number one so that's why we
                are available on every platform.
              </p>

              <div className='d-flex my-4 app-link-img justify-content-start'>
                <a
                  href='https://play.google.com/store/apps/details?id=com.app.heita'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img className='app-img' src={playStore} alt='' />
                </a>
                <a
                  href='https://apps.apple.com/app/id1622564893'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='mx-4'
                >
                  <img className='app-img' src={appStore} alt='' />
                </a>
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-7'>
            <img src={availablenew} className='img-fluid available_img' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Available;
