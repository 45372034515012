import React from 'react';
import { logo, facebookImg, twitterImg, youtubeImg, linkedin } from '../../assets/images';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-8'>
            <div className='col-12 col-lg-10'>
              <div className='d-flex align-items-end'>
                <img src={logo} className='d-none d-lg-block' />
                <p className='ms-3 footer_desc'>
                  With business operations in Cape Town | South Africa and Chennai | India, HEITA
                  aims to be the global leader in bell notifications. Starting small, however, we
                  have some great ideas to follow, just watch this space.
                </p>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-4'>
            <span>Follow Us On</span>

            <div className='d-flex'>
              <a className='nav_icons' href='https://www.facebook.com/heitaapp' target='_blank'>
                <i className='fab fa-facebook-f'></i>
                {/* <img src={facebookImg} className='img-fluid' /> */}
              </a>
              <a className='nav_icons' href='https://twitter.com/HeitaApp' target='_blank'>
                <i className='fab fa-twitter'></i>
                {/* <img src={twitterImg} className='img-fluid' /> */}
              </a>
              <a className='nav_icons' href='https://www.instagram.com/heitaapp/' target='_blank'>
                {/* <i className='fab fa-linkedin-square'></i> */}
                <img src={linkedin} className='img-fluid' />
              </a>
              <a className='nav_icons' href='https://www.youtube.com/@heitaapp9202' target='_blank'>
                <i className='fab fa-youtube'></i>
                {/* <img src={youtubeImg} className='img-fluid' /> */}
              </a>
            </div>
          </div>
          <hr className='mt-2' />
        </div>

        <div className='row pb-4'>
          <div className='col-12 col-lg-8'>
            <div className='d-flex justify-content-around footer_links flex-wrap'>
              <Link className='ms-2 ms-lg-0 mt-2 text-white text-decoration-none mt-lg-0' to='/'>
                Home
              </Link>
              <a
                className='ms-2 ms-lg-0 mt-2 text-white text-decoration-none mt-lg-0'
                href='/#problems'
              >
                Probelms
              </a>
              <a
                className='ms-2 ms-lg-0 mt-2 text-white text-decoration-none mt-lg-0'
                href='/#contact'
              >
                About us
              </a>
              <a
                className='ms-2 ms-lg-0 mt-2 text-white text-decoration-none mt-lg-0'
                href='/#help'
              >
                How Heita helps you
              </a>
              <a
                className='ms-2 ms-lg-0 mt-2 text-white text-decoration-none mt-lg-0'
                href='/#contact'
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className='col-12 col-lg-4'>
            <div className='d-flex justify-content-around footer_links policy_links flex-wrap'>
              <Link
                to='/polices'
                className='ms-2 ms-lg-0 mt-2 text-white text-decoration-none mt-lg-0'
              >
                Privacy Policy
              </Link>
              <Link
                to='/terms'
                className='ms-2 ms-lg-0 mt-2 text-white text-decoration-none mt-lg-0'
              >
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>

        <div className='text-center footer_copy py-3'>
          <b>&copy;2022 Heita. All rights reserved</b>
        </div>
        <div style={{ height: '30px' }} />
      </div>
    </footer>
  );
};

export default Footer;
